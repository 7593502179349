import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  isShowToast: boolean;
  isShowLoadingToast: boolean;
  isHaveGift: boolean;
  isOpenPrivateGift: boolean;
  isOpenPrivateGiftPopup: boolean;
  toastContent: {
    isError?: boolean;
    code?: number;
    message?: string;
    description?: {
      status?: number;
      message?: string;
    };
  };
}

const initialState: State = {
  isLoading: false,
  isShowToast: false,
  isHaveGift: false,
  isShowLoadingToast: false,
  isOpenPrivateGift: true,
  isOpenPrivateGiftPopup: true,
  toastContent: {},
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state: State, action) => {
      if (state.isLoading != action.payload) {
        state.isLoading = action.payload;
      }
    },
    setLoadingToast: (state: State, action) => {
      state.isShowLoadingToast = action.payload;
    },
    showToast: (state: State, action) => {
      state.isShowToast = true;
      state.toastContent = action.payload;
    },
    clearToast: (state: State) => {
      state.isShowToast = false;
    },
    setIsOpenPrivateGift: (state: State, action) => {
      state.isOpenPrivateGift = action?.payload;
    },
    setIsOpenPrivateGiftPopup: (state: State, action) => {
      state.isOpenPrivateGiftPopup = action?.payload;
    },
    setIsHaveGift: (state: State, action) => {
      state.isHaveGift = action?.payload;
    },
  },
});

export const {
  setLoading,
  showToast,
  clearToast,
  setLoadingToast,
  setIsOpenPrivateGift,
  setIsOpenPrivateGiftPopup,
  setIsHaveGift,
} = commonSlice.actions;
export default commonSlice.reducer;

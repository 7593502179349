import { Modal } from 'antd-mobile';
import styles from './styles.module.scss';
import React, { useEffect } from 'react';
import { showData } from 'utils';
import { useDisclosure } from 'hooks';
import { SvgIcon } from 'components/shared';

type Props = {
  text?: string;
  modalTitle?: string;
  modalContent?: string;
};

function MoreInfoModal({ text, modalTitle, modalContent }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const openModal = () => {
    Modal.show({
      className: styles.modal,
      header: (
        <>
          <p
            className="font-xl color-black font-bold"
            style={{
              textAlign: 'center',
            }}
          >
            {showData(modalTitle)}
          </p>
        </>
      ),
      closeOnMaskClick: true,
      maskStyle: { background: 'rgba(0, 174, 239, 0.20)' },
      bodyStyle: {
        boxShadow: 'var(--shadow-01)',
      },
      content: (
        <>
          <p
            className="font-sm color-black font-light"
            style={{
              textAlign: 'center',
              whiteSpace: 'pre-wrap',
            }}
          >
            {showData(modalContent)}
          </p>
        </>
      ),
      afterClose: onClose,
      onClose: onClose,
    });
  };

  useEffect(() => {
    if (isOpen) {
      openModal();
    }
  }, [isOpen]);

  return (
    <div className={styles.wrapper} onClick={onOpen}>
      <SvgIcon
        src="/images/icon/more-info.svg"
        pathFill="var(--primary-color)"
        width="24px"
        height="24px"
      />
      <p
        className="font-sm color-black"
        style={{
          paddingBottom: '3px',
        }}
      >
        {showData(text)}
      </p>
    </div>
  );
}

export default React.memo(MoreInfoModal);

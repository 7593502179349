import { Modal } from 'antd-mobile';
import styles from './styles.module.scss';
import React, { useEffect } from 'react';
import { showData } from 'utils';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;

  alertTitle?: string;
  alertContent?: string;
};

function AlertModal({ alertTitle = '', alertContent = '', isOpen, onClose }: Props) {
  const openModal = () => {
    Modal.show({
      className: styles.modal,
      header: (
        <>
          <p className="font-xl color-black font-bold">{showData(alertTitle)}</p>
        </>
      ),
      closeOnMaskClick: true,
      maskStyle: { background: 'rgba(0, 174, 239, 0.20)' },
      bodyStyle: {
        boxShadow: 'var(--shadow-01)',
      },
      content: (
        <>
          <p
            className="font-sm color-black font-light"
            style={{
              textAlign: 'center',
            }}
          >
            {showData(alertContent)}
          </p>
        </>
      ),
      afterClose: onClose,
      onClose: onClose,
    });
  };

  useEffect(() => {
    if (isOpen) {
      openModal();
    }
  }, [isOpen]);

  return <></>;
}

export default React.memo(AlertModal);

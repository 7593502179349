import styles from './styles.module.scss';
import EmptyModal from 'components/modal/EmptyModal';
import SvgIcon from '../SVGIcon';
import Selection from 'components/input/Selection';
import { SecondaryButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import { MemberGiftType } from 'types';
import { isVN, showData } from 'utils';
import { DURATION_UNIT } from 'enum';
import ConfirmModal from 'components/modal/ConfirmModal';
import { useDisclosure } from 'hooks';

type Props = {
  isLoading?: boolean;
  selectedPlanId: number | null;
  giftInfo?: MemberGiftType;
  isOpen?: boolean;
  onClose?: () => void;
  onActivate: () => void;
  onChangePlan: (id: number | null) => void;
};

export default function PrivateGiftPopup({
  isLoading = false,
  selectedPlanId = null,
  isOpen = false,
  giftInfo,
  onClose,
  onActivate,
  onChangePlan,
}: Props) {
  const { t } = useTranslation();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();

  return (
    <EmptyModal
      isOpen={isOpen}
      onClose={onClose}
      bodyStyles={{
        marginTop: '-180px',
      }}
    >
      <div className={styles.popupWrapper}>
        <div className={styles.blockInfo}>
          <p className={styles.title1}>{t('title.get')}</p>
          <p className={styles.title2}>{showData(giftInfo?.duration?.value)}</p>
          <p className={styles.title3}>
            <span
              style={{
                fontWeight: 700,
              }}
            >{`${
              giftInfo?.duration?.unit === DURATION_UNIT.DAY
                ? t('title.experience-day')
                : t('title.experience-month')
            }\n`}</span>
            {t('title.for-any-guest')}
          </p>
          <SvgIcon
            src="/images/icon/gift.svg"
            width={30}
            style={{
              position: 'absolute',
              transform: 'rotate(-24deg)',
              left: 5,
              top: 50,
            }}
          />
          <SvgIcon
            src="/images/icon/gift.svg"
            width={38}
            style={{
              position: 'absolute',
              transform: 'rotate(20deg)',
              right: 5,
              top: 20,
            }}
          />
        </div>
        <div className={styles.controller}>
          <Selection
            options={(giftInfo?.paymentPlans || []).map((plan) => {
              const clubInfo = plan?.paymentPlanClubs[0];

              return {
                id: Number(plan?.id),
                title: String(isVN() ? clubInfo?.club?.nameVi : clubInfo?.club?.nameEn),
              };
            })}
            isSkeleton={isLoading}
            selectedId={selectedPlanId}
            placeholder="placeholder.choose-your-club"
            onChangeCity={onChangePlan}
            clearable={true}
            iconUrl="/images/icon/building.svg"
          />
        </div>
        <SecondaryButton
          disabled={!selectedPlanId}
          variant="high-light-blue"
          text={t('button.activate')}
          onClick={onOpenConfirm}
          style={{
            margin: 'auto',
          }}
        />
        <ConfirmModal
          isOpen={isOpenConfirm}
          onClose={onCloseConfirm}
          onConfirm={onActivate}
          confirmTitle={t('title.confirm')}
          confirmContent={t('paragraph.active-gift-confirm')}
        />
      </div>
    </EmptyModal>
  );
}

import { useMemo, useRef } from 'react';
import { useDisclosure } from 'hooks';
import styles from './styles.module.scss';
import { isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import SvgIcon from 'components/shared/SVGIcon';
import { useOnClickOutside } from 'usehooks-ts';
import { Skeleton } from 'antd-mobile';

interface Props {
  options: { id?: number; title?: string }[];
  selectedId: number | null;
  clearable?: boolean;
  onChangeCity: (id: number | null) => void;
  iconUrl?: string;
  isSkeleton?: boolean;
  placeholder?: string;
}

const Selection = ({
  options,
  selectedId,
  clearable = true,
  isSkeleton = false,
  onChangeCity,
  iconUrl = '/images/icon/search-normal.svg',
  placeholder = 'placeholder.select-your-city',
}: Props) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { t } = useTranslation();
  const ref = useRef(null);

  const handleClickOutside = () => {
    onClose();
  };

  useOnClickOutside(ref, handleClickOutside);

  const selectedCity = useMemo(() => {
    if (!isNull(selectedId)) {
      const city = options.find((city) => city.id === selectedId);

      return city;
    }

    return null;
  }, [selectedId]);

  const onChange = (cityId: number) => () => {
    onChangeCity(cityId);
    onToggle();
  };

  const handleClearSelect = () => {
    onChangeCity(null);
  };

  return (
    <div className={styles.searchClub}>
      <div className={styles.searchWrapper}>
        <div
          className={`${styles.search} ${
            selectedId ? styles.activeCity : isOpen ? styles.active : ''
          }`}
          onClick={onToggle}
        >
          <div className={styles.searchClubIcon}>
            <SvgIcon src={iconUrl} pathFill="var(--primary-color)" />
          </div>

          <div className={styles.searchClubInput}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  width: '100%',
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm color-grey font-light">
                {isNull(selectedId) ? t(placeholder) : selectedCity?.title}
              </p>
            )}
          </div>
        </div>
        {!isNull(selectedId) && clearable && (
          <div className={styles.closeIcon} onClick={handleClearSelect}>
            <SvgIcon
              src="/images/icon/close.svg"
              width="100%"
              height="100%"
              pathFill="var(--primary-text-color)"
            />
          </div>
        )}
      </div>
      <div ref={ref} className={`${styles.searchClubResult} ${isOpen ? styles.show : ''}`}>
        <ul className={styles.searchClubList}>
          {options.map((city) => (
            <li key={city.id} className={styles.searchClubItem} onClick={onChange(Number(city.id))}>
              {city.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Selection;

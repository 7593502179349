import { NavigateCard, PrimaryButton, SubscriptionInfoCard } from 'components';
import styles from './styles.module.scss';
import MoreInfoModal from 'components/modal/MoreInfoModal';
import { useTranslation } from 'react-i18next';
import { useDirection, useDisclosure } from 'hooks';
import { DATE_FORMAT, PLAN_NAME_MAPPING, ROUTES } from 'constant';
import { useEffect, useMemo, useState } from 'react';
import {
  delayNavigate,
  formatDate,
  formatPrice,
  generateClubName,
  isSuccessCode,
  isVN,
} from 'utils';
import { AuthService, UserService } from 'services';
import { ContractType } from 'types';
import ConfirmModal from 'components/modal/ConfirmModal';
import moment from 'moment';

export function SubscriptionTab() {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const userService = new UserService();
  const authService = new AuthService();
  const [contractInfo, setContractInfo] = useState<ContractType>();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();

  const handleSendVerifyOTP = async () => {
    const response = await authService.sendOTPVerify();
    const { code } = response;
    if (isSuccessCode(code) && contractInfo && contractInfo?.vnpaySubscriptionId) {
      goTo(ROUTES.SUBSCRIPTION_VERIFY_UPGRADE, {
        state: {
          vnpaySubscriptionId: contractInfo.vnpaySubscriptionId,
        },
      })();
    }
  };

  const getSubscriptionContractInfo = async () => {
    const response = await userService.getSubscriptionContract();
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setContractInfo(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const paymentPlanInfo = useMemo(() => {
    return contractInfo?.vnpaySubscription;
  }, [contractInfo]);

  useEffect(() => {
    getSubscriptionContractInfo();
  }, []);

  return (
    <div className={styles.wrapper}>
      <SubscriptionInfoCard
        isSkeleton={isLoading}
        planName={
          PLAN_NAME_MAPPING[paymentPlanInfo?.paymentPlan?.type as keyof typeof PLAN_NAME_MAPPING]
        }
        clubName={generateClubName(
          isVN()
            ? paymentPlanInfo?.paymentPlan?.paymentPlanClubs?.[0]?.club?.nameVi
            : paymentPlanInfo?.paymentPlan?.paymentPlanClubs?.[0]?.club?.nameEn,
        )}
        endDate={formatDate(contractInfo?.endDate)}
        nextBillingDate={formatDate(contractInfo?.nextBillingChargeDate)}
      />
      <div className={styles.controller}>
        <NavigateCard
          text={t('title.payment-method')}
          pathIcon="/images/icon/payment-method.svg"
          onClick={goTo(ROUTES.ACCOUNT_PAYMENT_METHOD)}
        />
        <MoreInfoModal
          text={t('title.cancellation-info')}
          modalTitle={t('title.cancellation-subscription-info')}
          modalContent={t('paragraph.cancellation-subscription', {
            joiningFee: formatPrice(Number(paymentPlanInfo?.paymentPlan?.joiningFee), true),
          })}
        />
        {contractInfo?.upgradePaymentPlan && (
          <PrimaryButton text={t('button.black-card-upgrade')} onClick={onOpenConfirm} />
        )}
      </div>
      <ConfirmModal
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        onConfirm={handleSendVerifyOTP}
        confirmTitle={t('title.confirm')}
        confirmContent={t('paragraph.black-card-upgrade', {
          endDate: moment(contractInfo?.endDate).add(1, 'day').format(DATE_FORMAT),
          newPrice: formatPrice(Number(contractInfo?.upgradePaymentPlan?.membershipFee || 0), true),
        })}
      />
    </div>
  );
}

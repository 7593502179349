import { useState } from 'react';
import styles from './styles.module.scss';
import { SvgIcon } from 'components/shared';
import { showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  title?: string;
  description?: string;
  code?: string;
  onCopyAction?: () => void;
};

export default function PublicPromotionCard({ title, description, code, onCopyAction }: Props) {
  const { t } = useTranslation();
  const [copying, setCopying] = useState(false);

  const handleCopy = () => {
    if (copying || !code) return;
    setCopying(true);
    navigator.clipboard.writeText(code);
    setTimeout(() => {
      setCopying(false);
    }, 3000);
    onCopyAction && onCopyAction();
  };

  return (
    <div className={styles.cardWrapper}>
      <p className="font-sm color-black font-bold">{showData(title)}</p>
      <div className={styles.divider} />
      <p className="font-xs color-black font-light">{showData(description)}</p>
      <div className={styles.codeBlock}>
        <p
          className="font-sm color-black font-bold"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {t('title.promo-code')}
        </p>
        <div
          className={styles.code}
          onClick={handleCopy}
          style={{
            background: copying ? 'var(--primary-color)' : 'var(--primary-text-color)',
          }}
        >
          <p>{copying ? t('title.copied-code') : showData(code)}</p>
          {copying ? (
            <SvgIcon
              pathFill="var(--white-text-color)"
              src="/images/icon/circle-check.svg"
              width={18}
              height={18}
            />
          ) : (
            <SvgIcon src="/images/icon/copy.svg" width={20} height={20} />
          )}
        </div>
      </div>
    </div>
  );
}

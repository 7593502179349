/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { SvgIcon } from 'components/shared';
import { Divider, Skeleton } from 'antd-mobile';
import { useDisclosure } from 'hooks';
import { E_PT_CONTRACT_STATUS } from 'enum';
import { showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  status?: E_PT_CONTRACT_STATUS;
  clubName?: string;
  ptPackageName?: string;
  paymentDate?: string;
  transactionId?: string;
  ptPackagePrice?: number;
  startDate?: string;
  endDate?: string;
  totalPayment: number;
  paidAmount: number;
  discount: number;
  isSkeleton?: boolean;
  isHideExpand?: boolean;
};

function PTContractDetailCard({
  clubName,
  ptPackageName,
  paymentDate,
  transactionId,
  ptPackagePrice,
  paidAmount,
  totalPayment,
  discount,
  startDate,
  endDate,
  isSkeleton = false,
  isHideExpand = false,
}: Props) {
  const { isOpen, onToggle } = useDisclosure(true);
  const { t } = useTranslation();

  const logo = useMemo(() => {
    return paymentDate ? '/images/icon/tick-circle.svg' : '/images/icon/pending.svg';
  }, [paymentDate]);

  const statusTitle = useMemo(() => {
    return paymentDate ? 'title.successful-payment' : 'title.pending-payment';
  }, [paymentDate]);

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.title}>
        <div className={styles.statusIcon}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: '100%',
                borderRadius: ' 100px',
              }}
            />
          ) : (
            <SvgIcon src={logo} />
          )}
        </div>
        <p className="color-dark-black">
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            t(statusTitle)
          )}
        </p>
      </div>

      <div
        className={`${styles.transactionInfo} ${isOpen ? styles.open : ''}`}
        style={{
          maxHeight: isOpen ? '100%' : '0px',
          overflow: 'hidden',
          transition: '0.2s linear',
        }}
      >
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>{t('title.club')}</p>
              <p className={styles.blockValue}>{showData(clubName)}</p>
            </>
          )}
        </div>
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>PT</p>
              <p className={styles.blockValue}>{showData(ptPackageName)}</p>
            </>
          )}
        </div>
        {paymentDate && (
          <div className={styles.blockContent}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-lg)',
                }}
              />
            ) : (
              <>
                <p className={styles.blockLabel}>{t('title.purchase-date')}</p>
                <p className={styles.blockValue}>{paymentDate}</p>
              </>
            )}
          </div>
        )}
        {transactionId && (
          <div className={styles.blockContent}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-lg)',
                }}
              />
            ) : (
              <>
                <p className={styles.blockLabel}>{t('title.transaction-code')}</p>
                <p className={styles.blockValueSmall}>{transactionId}</p>
              </>
            )}
          </div>
        )}
        <Divider
          style={{
            margin: 0,
            borderColor: 'var(--secondary-text-color)',
            borderStyle: 'dashed',
          }}
        />
        {/* <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>{showData(ptPackageName)}</p>
              <p className={styles.blockValue}>{formatPrice(ptPackagePrice || 0)}</p>
            </>
          )}
        </div> */}
        {/* {paidAmount > 0 && !paymentDate && (
          <div className={styles.blockContent}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-lg)',
                }}
              />
            ) : (
              <>
                <p className={styles.blockLabel}>{t('title.paid-amount')}</p>
                <p
                  className={styles.blockValue}
                  style={{
                    color: 'var(--high-light-color)',
                  }}
                >
                  {`-${formatPrice(paidAmount || 0)}`}
                </p>
              </>
            )}
          </div>
        )} */}
        {/* {discount > 0 && (
          <div className={styles.blockContent}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-lg)',
                }}
              />
            ) : (
              <>
                <p className={styles.blockLabel}>{t('title.discount')}</p>
                <p
                  className={styles.blockValue}
                  style={{
                    color: 'var(--high-light-color)',
                  }}
                >
                  {`-${formatPrice(discount || 0)}`}
                </p>
              </>
            )}
          </div>
        )} */}
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>{t('title.start-date')}</p>
              <p className={styles.blockValue}>{showData(startDate)}</p>
            </>
          )}
        </div>
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>{t('title.end-date')}</p>
              <p className={styles.blockValue}>{showData(endDate)}</p>
            </>
          )}
        </div>
        {/* <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p
                className={styles.blockLabel}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {t('title.total')}
              </p>
              <p className={styles.blockValue}>
                {formatPrice(
                  (!paymentDate ? totalPayment - paidAmount - discount : totalPayment - discount) ||
                    0,
                )}
              </p>
            </>
          )}
        </div> */}
      </div>
      {!isHideExpand && (
        <div className={styles.toggleTag} onClick={onToggle}>
          <SvgIcon
            className={styles.arrow}
            src="/images/icon/arrow-down.svg"
            style={{
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
            }}
          />
          <SvgIcon className={styles.background} src="/images/icon/trapezoid.svg" />
        </div>
      )}
    </div>
  );
}

export default React.memo(PTContractDetailCard);

import React from 'react';
import styles from './styles.module.scss';
import { SvgIcon } from 'components/shared';
import { showData } from 'utils';

type Props = {
  text?: string;
  pathIcon?: string;
  onClick?: () => void;
};

function NavigateCard({ text = '', pathIcon = '', onClick }: Props) {
  return (
    <div className={styles.wrapper} onClick={onClick}>
      {pathIcon && <SvgIcon src={pathIcon} width="24px" height="24px" />}
      <p
        className="font-sm font-medium color-black"
        style={{
          whiteSpace: 'nowrap',
          width: 'calc(100% - 50px)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {showData(text)}
      </p>
      <div className={styles.icon}>
        <SvgIcon src="/images/icon/right-arrow.svg" width="15px" height="15px" />
      </div>
    </div>
  );
}

export default React.memo(NavigateCard);

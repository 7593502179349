import styles from './styles.module.scss';
import { Popover, SpaceProps } from 'antd-mobile';
import SvgIcon from 'components/shared/SVGIcon';
import { ROUTES } from 'constant';
import { useDirection } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'stores';
import { commonSelector, setIsOpenPrivateGift, setIsOpenPrivateGiftPopup } from 'stores/common';

type Props = {
  isHaveUnreadNotify?: boolean;
};

export default function Header({ isHaveUnreadNotify = false, ...rest }: Props & SpaceProps) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const dispatch = useDispatch();
  const { isOpenPrivateGiftPopup, isOpenPrivateGift, isHaveGift } = useSelector(commonSelector);

  const handleOpenGiftPopup = () => {
    dispatch(setIsOpenPrivateGift(true));
    dispatch(setIsOpenPrivateGiftPopup(false));
  };

  return (
    <div className={styles.headerWrapper} {...rest}>
      <div className={styles.iconWrapper} onClick={goTo(ROUTES.ACCOUNT)}>
        <div className={styles.icon}>
          <SvgIcon src="/images/icon/user.svg" />
        </div>
      </div>
      <div className={styles.iconWrapper}>
        {/* <div className={styles.icon} onClick={goTo(ROUTES.SUPPORT)}>
          <SvgIcon src="/images/icon/head-phone.svg" />
        </div> */}
        {isHaveGift && (
          <Popover
            content={
              <div>
                <p className="color-black font-xs">{t('title.gift-from-tng')}</p>
              </div>
            }
            placement="bottom"
            visible={isOpenPrivateGiftPopup && !isOpenPrivateGift}
          >
            <div className={styles.icon} onClick={handleOpenGiftPopup}>
              <SvgIcon className={styles.shaking} src="/images/icon/gift-outline.svg" />
            </div>
          </Popover>
        )}

        <div className={styles.icon} onClick={goTo(ROUTES.NOTIFICATION)}>
          <SvgIcon src="/images/icon/notify.svg" />
          {isHaveUnreadNotify && <div className={styles.notifyIndicator} />}
        </div>
      </div>
    </div>
  );
}

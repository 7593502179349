import { Mask } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';
import { SvgIcon } from 'components/shared';
import { createPortal } from 'react-dom';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  bodyStyles?: React.CSSProperties;
};

function EmptyModal({ isOpen = false, onClose, bodyStyles, children }: Props) {
  return createPortal(
    <Mask
      visible={isOpen}
      onMaskClick={onClose}
      destroyOnClose
      disableBodyScroll
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 174, 239, 0.20)',
        zIndex: 1000,
      }}
    >
      <div className={styles.modalBody} style={{ ...bodyStyles }}>
        <SvgIcon
          className={styles.closeIcon}
          src="/images/icon/close-circle.svg"
          onClick={onClose}
        />
        {children}
      </div>
    </Mask>,
    document.body,
  );
}

export default React.memo(EmptyModal);

export const DEFAULT_EMPTY = '-';

export const DELAY_TRANSITION = 100;

export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATE_FORMAT_LOCALE_VN = 'DD MMMM, YYYY';

export const DATE_FORMAT_LOCALE_EN = 'MMMM DD, YYYY';

export const TIME_FORMAT = 'HH:mm';

export const TIME_FORMAT_FULL = 'HH:mm:ss';

export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const APP_NAME = 'The New Gym';

export const HEADER_HEIGHT = 54;

export const THEME_COLOR = {
  MAIN: '#F2FAFC',
  SUB: '#00AEEF',
  BLACK: '#121212',
  WHITE: '#ffffff',
};

export const UNIT_PRICE = 'Đ';

export const OTP_RESENT_DURATION = 30;

export const TEST_GIF = {
  '155169': '/images/gif/test-2.gif',
  '191732': '/images/gif/test-1.gif',
  '154540': '/images/gif/test-3.gif',
  '216729': '/images/gif/test-4.gif',
};

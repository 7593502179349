export enum LANGUAGE {
  VN = 'vi',
  EN = 'en',
}

export enum PAYMENT_METHOD {
  ONLINE = '2',
  PAYMENT = '1',
}

export enum TRANSACTION_TYPE {
  PT = 'PT',
  MEMBERSHIP = 'MEMBERSHIP',
  FREEZE = 'FREEZE',
}

export enum RECOGNIZE_TYPE {
  ID_CARD = '1',
  PASSPORT = '2',
}

export enum PAYMENT_GROUP {
  PAY_IN_COUNTER = '1',
  ONLINE = '2',
}

export enum ACCOUNT_TYPE {
  EMPLOYEE = 'EMPLOYEE',
  MEMBER = 'MEMBER',
  PT = 'PT',
  LEADER = 'LEADER',
  GM = 'GM',
}

export enum PAYMENT_PLAN_TYPE {
  BLACK_CARD = 'BLACK_CARD',
  BASIC = 'BASIC',
  CLASSIC = 'CLASSIC',
  SUBSCRIPTION = 'SUBSCRIPTION',
  BLACK_CARD_SUBSCRIPTION = 'BLACK_CARD_SUBSCRIPTION',
}

export enum DURATION_UNIT {
  MONTH = 'month',
  DAY = 'day',
}
